import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RolePermissionsModel } from '../../auth/models/account-info.model';
import { PermissionModel } from '../../auth/models/permissions.model';
import { ActionTypeModel, BrandActionPayload } from '../models/action-type.model';
import { ActionsRuleVariableModel } from '../models/actions-rule-variable.model';
import { ApplicationViewModel } from '../models/application-view.model';
import { BackendErrorResponseModel, BackendOkResponse } from '../models/backend-response.model';
import {
  BrandSaveModel,
  CompanyModel,
  DictionaryModel,
  DictionaryResponse,
  DropdownModel,
  ProductModel,
  SubcategoryModel,
  TimezoneModel,
} from '../models/dropdown.model';
import { BrandModel } from '../models/dropdown.model';
import { ResultsModel } from '../models/results.model';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  apiURL: string = environment.api;
  newApiURL: string = environment.newApi;

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
    private logger: NGXLogger,
  ) {}

  private catchGetDictionaryError(err) {
    this.logger.warn(err.message);
    return of([]);
  }

  /**
   * Reset cache before next request
   */
  invalidateCache(): DictionaryService {
    this.cacheService.invalidate();
    return this;
  }

  getBrands(): Observable<BrandModel[]> {
    const url = `${this.newApiURL}/brands`;
    return this.cacheService.getCachedData<DictionaryResponse<BrandModel>>('brands', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getBrandsByFilters(companyIDs: number[]): Observable<BrandModel[]> {
    const url = `${this.newApiURL}/brands/by-filters`;

    let params = new HttpParams();
    companyIDs.forEach((companyId) => {
      params = params.append('companyID', companyId);
    });

    return this.httpClient.get<DictionaryResponse<BrandModel>>(url, { params }).pipe(map((response) => response.items));
  }

  createBrand(newBrand: BrandSaveModel): Observable<BackendOkResponse | BackendErrorResponseModel> {
    const url = `${this.newApiURL}/brands`;
    return this.httpClient.post(url, newBrand);
  }

  getProducts(): Observable<ProductModel[]> {
    const url = `${this.newApiURL}/products`;
    return this.cacheService.getCachedData<DictionaryResponse<ProductModel>>('products', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getProductsByFilters(brandIDs: number[]): Observable<ProductModel[]> {
    const url = `${this.newApiURL}/products/by-filters`;

    let params = new HttpParams();
    brandIDs.forEach((brandID) => {
      params = params.append('brandID', brandID);
    });

    return this.httpClient
      .get<DictionaryResponse<ProductModel>>(url, { params })
      .pipe(map((response) => response.items));
  }

  updateBrandProducts(
    brandId: number,
    updatedBrand: Partial<BrandSaveModel>,
    deactivateFlows: boolean = false,
  ): Observable<DictionaryResponse<DictionaryModel> & BackendOkResponse> {
    const url = `${this.newApiURL}/brands/${brandId}/products`;

    const options = deactivateFlows ? { params: new HttpParams().set('deactivateFlows', deactivateFlows) } : {};
    return this.httpClient.put<DictionaryResponse<DictionaryModel> & BackendOkResponse>(url, updatedBrand, options);
  }

  public getCompanyPerBrand(brandID: number) {
    return this.getBrands().pipe(map((brands) => brands?.find((brand) => brand.id === brandID)?.companyID));
  }

  getScheduleOptions(): Observable<DictionaryModel[]> {
    const url = `${this.newApiURL}/getscheduleoptions`;
    return this.cacheService.getCachedData<DictionaryResponse<DictionaryModel>>('scheduleoptions', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getCategories(): Observable<DictionaryModel[]> {
    const url = `${this.newApiURL}/categories`;
    return this.cacheService.getCachedData<DictionaryResponse<DictionaryModel>>('categories', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getSubcategories(categoryId?: number): Observable<SubcategoryModel[]> {
    const url = `${this.newApiURL}/subcategories`;

    return this.cacheService.getCachedData<DictionaryResponse<SubcategoryModel>>(`/subcategories`, url).pipe(
      map((result) => {
        if (categoryId) {
          return result.items.filter((subcategory) => subcategory.categoryID === Number(categoryId));
        }
        return result.items;
      }),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getFlowStatuses() {
    const url = `${this.newApiURL}/flowstatuses`;
    return this.cacheService.getCachedData<DictionaryResponse<DictionaryModel>>('flowstatuses', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  public getEventsList(): Observable<ActionTypeModel[]> {
    return this.getActionTypes().pipe(
      map((actionTypes) => actionTypes.filter((actionType) => actionType.isEventTrigger)),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  public getActionsList(): Observable<ActionTypeModel[]> {
    return this.getActionTypes().pipe(
      map((actionTypes) => actionTypes.filter((actionType) => !actionType.isEventTrigger && !actionType.rule)),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  public getRuleActionsList(): Observable<ActionTypeModel[]> {
    return this.getActionTypes().pipe(
      map((actionTypes) => actionTypes.filter((actionType) => !actionType.isEventTrigger && actionType.rule)),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  public getActionTypes(): Observable<ActionTypeModel[]> {
    const url = `${this.newApiURL}/v2/actionTypes`;

    return this.cacheService
      .getCachedData<DictionaryResponse<ActionTypeModel>>('actionTypes', url, new HttpParams())
      .pipe(
        map((result) => result.items),
        catchError(this.catchGetDictionaryError.bind(this)),
      );
  }

  updateBrandActionsOrEvents(
    brandId: number,
    payload: BrandActionPayload,
    deactivateFlows: boolean = false,
  ): Observable<DictionaryResponse<DictionaryModel> & BackendOkResponse> {
    const url = `${this.newApiURL}/brands/${brandId}/actions`;

    const options = deactivateFlows ? { params: new HttpParams().set('deactivateFlows', deactivateFlows) } : {};
    return this.httpClient.put<DictionaryResponse<DictionaryModel> & BackendOkResponse>(url, payload, options);
  }

  public getKycActions(brandID: number): Observable<DictionaryModel[]> {
    const url = `${this.newApiURL}/kycActions/by-filters`;
    const params = new HttpParams().appendAll({ brandID });

    return this.cacheService
      .getCachedData<DictionaryResponse<DictionaryModel>>(`kycActions_${brandID}`, url, params)
      .pipe(
        map((result) => result.items),
        catchError(this.catchGetDictionaryError.bind(this)),
      );
  }

  getActionRuleVariables(): Observable<ActionsRuleVariableModel[]> {
    const url = `${this.newApiURL}/actionTypes/ruleVariables`;
    return this.cacheService.getCachedData<DictionaryResponse<ActionsRuleVariableModel>>('ruleVariables', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getActionRuleVariablesForAction(id: number): Observable<ActionsRuleVariableModel[]> {
    const url = `${this.newApiURL}/actionTypes/${id}/ruleVariables`;
    return this.cacheService
      .getCachedData<DictionaryResponse<ActionsRuleVariableModel>>(`ruleVariables_${id}`, url)
      .pipe(
        map((result) => result.items),
        catchError(this.catchGetDictionaryError.bind(this)),
      );
  }

  public getApplicationViews(): Observable<ApplicationViewModel[]> {
    const url = `${this.newApiURL}/applications/views`;

    return this.cacheService.getCachedData<DictionaryResponse<ApplicationViewModel>>(`applications/views`, url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getPeriods(): Observable<DictionaryModel[]> {
    const url = `${this.newApiURL}/timePeriods`;
    return this.cacheService.getCachedData<DictionaryResponse<DictionaryModel>>('timePeriods', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getTriggersTypes(): Observable<DictionaryModel[]> {
    const url = `${this.newApiURL}/triggerTypes`;
    return this.cacheService.getCachedData<DictionaryResponse<DictionaryModel>>('triggerTypes', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getCompanies(): Observable<CompanyModel[]> {
    const url = `${this.newApiURL}/companies`;
    return this.cacheService.getCachedData<DictionaryResponse<CompanyModel>>('companies', url, new HttpParams()).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  createCompany(newCompany: CompanyModel): Observable<BackendOkResponse | BackendErrorResponseModel> {
    const url = `${this.newApiURL}/companies`;
    return this.httpClient.post(url, newCompany);
  }

  updateCompany(company: CompanyModel) {
    const url = `${this.newApiURL}/companies/${company.id}`;
    return this.httpClient.put(url, company, { observe: 'response' });
  }

  getVerificationsStatuses(): Observable<DictionaryModel[]> {
    const url = `${this.newApiURL}/verifications/statuses`;
    return this.cacheService.getCachedData<DictionaryResponse<DictionaryModel>>('items', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getTimezones(): Observable<TimezoneModel[]> {
    const url = `${this.newApiURL}/timezones`;
    return this.cacheService
      .getCachedData<DictionaryResponse<TimezoneModel>>('timezones', url)
      .pipe(map((result) => result.items));
  }

  getPermissions(): Observable<PermissionModel[]> {
    const url = `${this.newApiURL}/permissions`;
    return this.cacheService.getCachedData<{ items: PermissionModel[] }>('permissions', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  getRolePermissions(): Observable<RolePermissionsModel[]> {
    const url = `${this.newApiURL}/rolePermissions`;
    return this.cacheService.getCachedData<{ items: RolePermissionsModel[] }>('rolePermissions', url).pipe(
      map((result) => result.items),
      catchError(this.catchGetDictionaryError.bind(this)),
    );
  }

  /** not migrated yet */

  getOperators(): Observable<ResultsModel<DropdownModel>> {
    const url = this.apiURL + 'getoperators';
    return this.httpClient.get<ResultsModel<any>>(url);
  }

  public getDropdownWithAllValue(items: any[], id: number = null) {
    return [
      {
        id,
        ID: id,
        name: 'All',
      },
      ...items,
    ];
  }
}
